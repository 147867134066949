<template>
  <div class="home home_bgimg flex-column-between">
    <div class="home_her">
      <header class="flex-align-between head">
        <div class="avatar flex-align">
          <div class="avatarImgb">
            <img class="avatarImg" :src="avatar1" v-if="succeed" @click="goUser" />
            <img class="avatarImg" src="@/assets/user/user.png" v-else @click="goUser" />
          </div>
          <div class="avatarname flex-column-between">
            <p id="tn">
              {{ UserInfo_data.name }}
            </p>

            <p>上课时长：{{ time(teacherTime) }}</p>
            <p>上课字数：{{ teachercount }} 个</p>
          </div>
        </div>
        <div class="schoolName flex-align-around">
          <span class="school_dot"></span>
          <span>
            {{ schoolName }}
          </span>
          <span class="school_dot"></span>
        </div>
        <div class="more">
          <img src="@/assets/homepage/assist.png" class="item" @click="question" />
          <img src="@/assets/homepage/exit.png" class="item" @click="exit" />
        </div>
      </header>
      <div class="entrance flex-align-center">
        <ul class="flex-align-between">
          <li v-for="(item, i) in navs" :key="i">
            <div class="vertical" @click="access(item)" :style="{ backgroundImage: 'url(' + item.imgs + ')' }">
              <span>{{ item.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="home_btm">
      <div class="taibg"></div>
      <!-- 上课弹出框 -->
      <div class="dialogbox">
        <el-dialog :visible.sync="centerDialogVisible" top="90px" class="attenddialog" title="选择课程" center>
          <div class="containe">
            <div class="classgroup flex-layout">
              <div class="Title">选择班级：</div>
              <div class="classList" :class="unfoldShow ? 'classTall2' : 'classTall1'">
                <ul :class="unfoldShow ? 'ul1' : 'ul2'">
                  <!-- <li>{{defaultUnfold}}</li> -->
                  <li :class="classIndex == index ? 'li1' : 'li2'" v-for="(item, index) in classArr" :key="index"
                    @click="classBtn(index, item.termId, item.grade, item.id)">
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <div class="more flex-align-between" @click="unfold" v-if="classArr.length > 4 ? true : false">
                <span>更多</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
            </div>
            <div class="unitgroup flex-layout">
              <div class="Title Title3">选择图书：</div>
              <!-- <span>{{ item.weekGroupName }}</span> -->
              <div class="wordageList flex-layout">
                <div class="arrangeBox flex-align-center">
                  <div class="wordagebox2" v-if="bookList.length>0">
                    <ul class="flex-column-center">
                      <li class="wordTextSon1" @click="selectBook(index,item.classId,item.link,item.wordId)" v-for="(item,index) in bookList" :key="index"
                        :class="{'wordTextSon2':currentIndex==index}">{{item.bookName}}</li>
                    </ul>
                  </div>
                  <div class="wordagebox3 flex-column-center" v-else>
                    <img src="../../assets/empty.png" />
                    <span>暂无内容</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="goclassRoom()">确定</el-button>
          </span>
        </el-dialog>
      </div>
      <!-- 上课弹出框 -->
      <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
      <div class="hint">
        <el-dialog title="提示" :visible.sync="centerDialogVisible3" width="30%" center>
          <div class="tip flex-align-center">
            {{ textValue }}
          </div>
          <div class="spanstyle flex-align-center">
            <span @click="centerDialogVisible3 = false"> 关闭 </span>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
  import pdf from "vue-pdf";
  import {
    mapState,
    mapMutations
  } from "vuex";
  import subscribe from "../../Common_components/Popup/subscribe.vue";
  import Axios from "axios";

  export default {
    components: {
      subscribe,
      pdf,
    },
    name: "Home",
    data() {
      return {
        // 教师信息
        UserInfo_data: "",
        schoolName: "",
        schoolId: "",
        avatar1: "",
        //班级信息
        classArr: [],
        class_id: "",
        classIndex: 0,
        defaultUnfold: "",
        gradeId: "",
        termId: "",
        noonClassId: "",
        classId: "",
        //课堂模式--单元
        Company_data: "",
        goclassType: 1,
        //午写模式--周--每周文字
        weeklist: [],
        weeklist1: [{
          classLearnedWordsList: [],
          weekGroup: "",
        }, ],
        wordTextIndex: "",
        wordTextName: "",
        wordageId: "",
        //征订判断
        school_condition: false,
        class_condition: false,
        // 上课弹窗
        centerDialogVisible: false,
        centerDialogVisible2: false,
        unitShow: false,
        isShow: true,
        numPages: null,
        avatar: "null",
        avatarShow: true,
        defaultImg: require("@/assets/homepage/tou.png"),
        backgroundImage: "url(" + require("@/assets/homepage/bg.png") + ")",
        navs: [{
            name: "我教的课",
            href: "/attend",
            imgs: require("@/assets/study/class.png"),
          },
          {
            name: "我的互动课件",
            href: "/activity",
            imgs: require("@/assets/study/Courseware.png"),
          },
          {
            name: "作品集",
            href: "/activity",
            imgs: require("@/assets/study/words.png"),
          },
          // {
          //   name: "作业",
          //   href: "/activity",
          //   imgs: require("@/assets/homepage/attendclassbg.png"),
          // },
          // {
          //   1: {
          //     name: "我的直播",
          //     href: "",
          //     imgs: require("../../assets/homepage/live.png"),
          //   },
          //   2: {
          //     name: "课程广场",
          //     href: "",
          //     imgs: require("../../assets/homepage/Coursesquare.png"),
          //   },
          // },
        ],
        centerDialogVisible3: false,
        ruleForm: "",
        class_isActive: 0,
        Company_isActive: 0,
        curriculum_isActive: 0,
        pdfUrl: "",
        //新功能
        iconShow: 0,
        taskText: "生字学习",
        unfoldShow: false,
        unitArr: [],
        task1: "",
        taskResult1: false,
        task2: "",
        taskResult2: false,
        taskImgarr1: [],
        taskImgarr2: [],
        taskId1: "",
        taskId2: "",
        // unitSonIndex: [],
        unitSingle1: [],
        unitSingle2: [],
        aloneId: "",
        textValue: "",
        // 新版
        weeklistName: {},
        weekIndex: 0,
        weektaskId: "",
        weekpop: "",
        // 页面缓存
        isFirstEnter: false,
        url: "../../public/4.pdf",
        pageTotalNum: 1,
        loadedRatio: 0,
        pageShow: false,
        //接收消息
        websocket: null,
        //全屏
        fullscreen: false,
        classroom: [],
        UnitLearnIndex: null,
        UnitLearnName: null,
        UnitLearnId: null,
        pattern: null,
        unitId: "",
        UserID: "",
        category: [
          // {
          //   name: "入门课例",
          //   href: "/classExample",
          //   imgs1: require("../assets/homepage/classExample1.png"),
          //   imgs2: require("../assets/homepage/classExample2.png"),
          // },
          {
            name: "我的报告",
            href: "/sampleReels",
            imgs1: require("@/assets/homepage/report1.png"),
            imgs2: require("@/assets/homepage/report2.png"),
          },
          {
            name: "作品集",
            href: "",
            imgs1: require("@/assets/homepage/showreel1.png"),
            imgs2: require("@/assets/homepage/showreel2.png"),
          },
        ],
        categoryType: false,
        categoryIndex: null,
        teacherTime: 0,
        teachercount: 0,
        succeed: false,
        judgeStatus: false,
        currentIndex: 0, // 选择图书默认index
        bookList: [],
        currentLink: '',
        currentClassId:'',
        currentWordId:''
      };
    },
    computed: {
      message: {
        get() {
          return this.$store.state.home.message;
        },
        set() {},
      },
    },
    created() {
      document.addEventListener("click", (e) => {
        if (this.$refs.showPanel) {
          let isSelf = this.$refs.showPanel.contains(e.target);
          if (!isSelf) {
            this.isShow = false;
          }
        }
      });
    },
    mounted() {
      this.$nextTick(() => {
        this.Personnel_information();
      });
      this.getTeacherUploadInfo();
      this.judgeUser();
      // this.$AccessPortrait.Portrait()
    },
    methods: {
      ...mapMutations(["attend_data", "hint_data", "message_data"]),
      selectBook(index,classId,link,wordId) {
        this.currentIndex = index;
        this.currentClassId = classId;
        this.currentLink = link;
        this.currentWordId = wordId
      },
      //模块入口（上课--活动--作品集）
      async access(e) {
        let _this = this;
        if (e.name == "我教的课") {
          this.school_subscrip();
        } else if (e.name == "我的互动课件") {
          window.open("https://teacher.qq.com/#/course/created-quda");
        } else {
          this.$router.push({
            path: "/studyCurriculum",
            query: {
              type: "initial"
            }
          });
        }
      },
      //腾讯报告
      async toReDirectReport() {
        let resData = await this.$Api.Home.getcheckTencentUser();
        if (resData.data) {
          let data = {
            userId: this.UserID,
          };
          let value = await this.$Api.Home.toReDirectReport(data);
          window.open(value.data);
        } else {
          this.hint_data("暂无报告");
          this.centerDialogVisible2 = true;
        }
        //
      },
      async judgeUser() {
        let resData = await this.$Api.Home.getcheckTencentUser();
        this.judgeStatus = resData.data;
      },

      //教师信息
      async Personnel_information() {
        let resData = await this.$Api.Home.getUserInfo();
        this.UserInfo_data = resData.data;
        this.schoolName = this.UserInfo_data.schoolName;
        this.schoolId = this.UserInfo_data.schoolId;
        if (this.schoolId == "180708") {
          this.category.push({
            name: "数据驾驶舱",
            href: "",
            imgs1: require("@/assets/homepage/cockpit1.png"),
            imgs2: require("@/assets/homepage/cockpit2.png"),
          });
        }
        this.avatar1 =
          "https://yunapi.cnuzi.com/uploads/" + this.UserInfo_data.avatar;
        this.errorimg(this.avatar1);
        this.avatar = this.UserInfo_data.avatar;
        sessionStorage.setItem(
          "avatar",
          JSON.stringify(this.UserInfo_data.avatar)
        );
        sessionStorage.setItem("teacherName", resData.data.name);
        sessionStorage.setItem("teacherId", resData.data.id);
        this.UserID = resData.data.id;
        this.$Api.Home.getCourseInfo()
          .then((res) => {
            if (res.code == 500) {} else {
              this.classArr = res.data;
              this.class_id = this.classArr[0].id;
              this.weeklist = [];
              this.weeklist1 = [{
                classLearnedWordsList: [],
                weekGroup: "",
              }, ];
              this.classBtn(
                0,
                res.data[0].termId,
                res.data[0].grade,
                res.data[0].id
              );
              if (this.classArr[0].unitInfo != undefined) {
                this.Company_data = this.classArr[0].unitInfo;
              }
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      // 征订判断（学校征订--班级征订）
      async school_subscrip() {
        let resData = await this.$Api.Home.school_subscription();
        this.school_condition = resData.data.result;
        if (this.school_condition) {
          this.centerDialogVisible = true;
        } else {
          this.hint_data("暂无权限 ，请联系管理员开通！");
          // this.hint_data("该学校尚未开通过征订活动！");
          this.centerDialogVisible2 = true;
        }
      },

      //防止闪图
      errorimg(src) {
        let that = this;
        let img = new Image();
        img.src = src;
        img.onload = function () {
          that.succeed = true;
        };
        img.onerror = function () {
          that.succeed = false;
        };
      },
      //退出
      exit() {
        // this.message_data(true)
        this.$confirm("此操作将退出应用, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            center: true,
            customClass: "MessClass",
            confirmButtonClass: "warningClass",
          })
          .then(() => {
            this.$router.push({
              name: "logins",
              params: {
                type: 1,
              },
            });
          })
          .catch(() => {});
      },
      //帮助手册
      question() {
        this.$router.push({
          name: "Helpmanual",
        });
      },
      clear(bool) {
        this.centerDialogVisible2 = bool;
      },
      message() {
        this.hint_data("敬请期待！");
        this.centerDialogVisible2 = true;
      },
      //新功能
      //点击班级
      async classBtn(index, termId, grade, id) {
        console.log('index-', index, 'termId-', termId, 'grade-', grade, 'id-', id)
        this.classIndex = index;
        this.defaultUnfold = this.classArr[index].name;
        // 根据班级id获取图书列表
        let data = {
          classId: id
        }
        let resData = await this.$Api.Home.getBook(data)
        console.log('图书列表', resData);
        this.bookList = resData.data;
        this.currentLink = resData.data[0].link;
        this.currentClassId = resData.data[0].classId;
        this.currentWordId = resData.data[0].wordId
        // 班级id
        // this.classId = id;
        // this.noonClassId = id;
        // this.gradeId = grade;
        // this.termId = termId;
        // //课堂
        // this.goclassType = 1;
        // this.unitArr = [];
        // this.weeklist = [];
        // this.classroom = [];
        // this.getUnittable(termId, id);
        // //午写
        // this.weeklist1 = [];
        // this.wordTextIndex = "";
        // this.wordTextName = "";
        // this.wordageId = "";
        // this.getweekList(grade, id, termId);
        // this.unfoldShow = false;
      },
      // //课堂模式
      // async getUnittable(termId, id) {
      //   this.unitArr = [];
      //   this.classroom = [];
      //   let data = {
      //     termId: termId,
      //     classId: id,
      //   };
      //   let resData = await this.$Api.Myclass.getUnittable(data);
      //   this.unitArr = resData.data;
      //   let Index = 0;
      //   if (resData.data.length == 0) return false;
      //   for (let i = 0; i < this.unitArr.length; i++) {
      //     if (this.unitArr[i].lastTaskUnitResult) {
      //       Index = i;
      //     }
      //   }
      //   this.unitclick(Index, this.unitArr[Index]);
      // },
      // async unitclick(index, valve) {
      //   this.unitId = valve.unitId;
      //   this.classroom = [];
      //   this.iconShow = index;
      //   let data = {
      //     gradeId: valve.termId,
      //     classId: this.classId,
      //     unitId: valve.unitId,
      //   };
      //   let resData = await this.$Api.Myclass.getClassWordsVoList(data);
      //   if (resData.code == 500) return false;
      //   // console.log("resData.data[0].unitName", resData.data[0].unitName);
      //   // console.log("this.judgeStatus", this.judgeStatus);
      //   // console.log("resData.data[0].wordList", resData.data[0].wordList);
      //   if (resData.data[0].unitName == "第八单元" && this.judgeStatus) {
      //     resData.data[0].wordList.push({
      //       lastresult: false,
      //       result: "false",
      //       wordId: -1,
      //       wordName: "五色鹦鹉图",
      //     }, {
      //       lastresult: false,
      //       result: "false",
      //       wordId: -2,
      //       wordName: "生活中的吉语",
      //     }, {
      //       lastresult: false,
      //       result: "false",
      //       wordId: -3,
      //       wordName: "你不知道的青铜器-利簋",
      //     }, {
      //       lastresult: false,
      //       result: "false",
      //       wordId: -4,
      //       wordName: "动物王国开大会",
      //     }, {
      //       lastresult: false,
      //       result: "false",
      //       wordId: -5,
      //       wordName: "稻草人",
      //     });
      //   }
      //   let arr = resData.data[0].wordList;
      //   // console.log("arr", arr);
      //   let result = [];
      //   let len = arr.length;
      //   for (var i = 0; i < len; i += 5) {
      //     result.push(arr.slice(i, i + 5));
      //   }
      //   this.classroom = result;
      //   // console.log("this.classroom", this.classroom);
      // },
      // UnitLearning(name, index, wordId) {
      //   this.UnitLearnIndex = index;
      //   this.UnitLearnName = name;
      //   this.wordageId = wordId;
      //   this.pattern = 0;
      // },
      // 午写模式
      // weekClick(weekGroupId, index) {
      //   this.weekIndex = index;
      //   this.wordTextIndex = "";
      //   this.wordTextName = "";
      //   this.wordageId = "";
      //   this.weeklist1 = [];
      //   this.getweek(weekGroupId, this.classId, this.termId);
      // },
      // individual(name, index, wordId) {
      //   this.wordTextIndex = index;
      //   this.wordTextName = name;
      //   this.wordageId = wordId;
      //   this.pattern = 1;
      //   this.getweektask(this.wordageId);
      // },
      // 午写taskid
      // async getweektask(wordId) {
      //   let data = {
      //     wordId: wordId,
      //   };
      //   let resData = await this.$Api.Myclass.getTaskIdByWordId(data);
      //   this.weektaskId = resData.data;
      // },
      // 午写周请求
      // async getweekList(gradeID, classId, termId) {
      //   this.weeklist = [];
      //   let data = {
      //     gradeId: termId,
      //     classId: classId,
      //   };
      //   let resData = await this.$Api.Myclass.getWeekIdsByGradeId(data);
      //   console.log('resData',resData)
      //   this.weeklist = resData.data;
      //   this.weeklist = [{
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 1,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 2,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 3,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 4,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 5,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 6,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 7,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //     {
      //       lastLearnedWordInWeekGroup: false,
      //       weekGroup: 8,
      //       weekGroupName: "《黄叶儿跳舞》",
      //       weekGroupResult: false,
      //     },
      //   ];
      //   console.log("this.weeklist", this.weeklist);
      //   if (resData.data.length == 0) return false;
      //   for (let i = 0; i < this.weeklist.length; i++) {
      //     if (this.weeklist[i].lastLearnedWordInWeekGroup) {
      //       this.weekIndex = i;
      //     }
      //   }

      //   this.getweek(resData.data[this.weekIndex].weekGroup, classId, termId);
      // },
      // async getweek(id, classId, termId) {
      //   let data = {
      //     gradeId: termId,
      //     classId: classId,
      //     weekGroupId: id,
      //   };
      //   let resData = await this.$Api.Myclass.getWordsVoList(data);
      //   this.weeklist1 = resData.data;
      // },
      // 点击上课
      goclassRoom() {
        console.log('跳转助手');

        if (!this.currentLink) {
          this.$message({
            message: '暂未开放！',
            type: 'warning'
          });
        } else {
          window.open(this.currentLink);
          this.centerDialogVisible = false;
          let data = {
            params: -1,
            wordId: this.currentWordId,
            classId: this.currentClassId,
          };
          this.$Api.Home.CreateLesson(data).then((res) => {});
        }


        // let _this = this;
        // if (!this.school_condition) return false;
        // if (this.wordageId == "") {
        //   this.centerDialogVisible3 = true;
        //   this.textValue = "请选择生字";
        //   return false;
        // }
        // let data = {
        //   classId: _this.classId,
        //   wordId: _this.wordageId,
        // };
        // _this.$Api.Home.checkTencentWord(data).then((res) => {
        //   if (res.data) {
        //     _this.CreateLesson();
        //     //腾讯跳转
        //     _this.queryWordUrl(this.wordageId);
        //     _this.centerDialogVisible = false;
        //   } else {
        //     let data = {
        //       wordId: _this.wordageId,
        //     };
        //     _this.$Api.Myclass.getImgList(data)
        //       .then((res) => {
        //         if (res.code == 500 || res.data.enclosure == undefined) {
        //           this.centerDialogVisible3 = true;
        //           this.textValue = "暂未开放!";
        //           return false;
        //         } else {
        //           _this.$router.push({
        //             path: "/pdfdemo",
        //             query: {
        //               taskId: _this.weektaskId,
        //               unitId: _this.unitId,
        //               wordId: _this.wordageId,
        //               classId: _this.classId,
        //               type: _this.pattern,
        //               gradeId: _this.gradeId,
        //               termId: _this.termId,
        //             },
        //           });
        //           _this.centerDialogVisible = false;
        //         }
        //       })
        //       .catch((err) => {
        //         console.log("err", err);
        //       });
        //   }
        // });
      },
      // queryWordUrl() {
      //   let data = {
      //     wordId: this.wordageId,
      //   };
      //   this.$Api.Home.queryWordUrl(data).then((res) => {
      //     window.open(res.data);
      //   });
      // },
      // // 统计时间
      // CreateLesson() {
      //   let value = {
      //     taskId: this.weektaskId,
      //     unitId: this.unitId,
      //     wordId: this.wordageId,
      //     classId: this.classId,
      //     type: this.pattern,
      //     gradeId: this.gradeId,
      //     termId: this.termId,
      //   };
      //   let data = {
      //     params: JSON.stringify(value),
      //     wordId: this.wordageId,
      //     classId: this.classId,
      //   };
      //   this.$Api.Home.CreateLesson(data).then((res) => {});
      // },
      // add() {
      //   this.hint_data("暂未开放");
      //   this.centerDialogVisible2 = true;
      // },
      // 删除
      // goassistant() {
      //   this.$router.push({
      //     name: "painter",
      //   });
      // },
      // unfold() {
      //   this.unfoldShow = !this.unfoldShow;
      // },
      // categoryenter(index) {
      //   this.categoryIndex = index;
      //   this.categoryType = true;
      // },
      // categoryleave(index) {
      //   this.categoryIndex = index;
      //   this.categoryType = false;
      // },

      async getTeacherUploadInfo() {
        this.$Api.Home.getTeacherUploadInfo().then((res) => {
          this.teacherTime = res.data.totalLength;
          this.teachercount = res.data.totalUpload;
        });
      },
      time(time) {
        var h = parseInt(
          ((time * 1000) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var m = parseInt(((time * 1000) % (1000 * 60 * 60)) / (1000 * 60));
        var s = parseInt(((time * 1000) % (1000 * 60)) / 1000);
        return h + "时" + m + "分" + s + "秒";
      },
      // addtTx() {
      //   window.open("https://teacher.qq.com/#/course/created-quda");
      // },
      goUser() {
        this.$router.push("/profile");
      },
    },
  };
</script>

<style lang="less" scoped>
  @import "./less/Home.less";
</style>
<style>
  .pdf {
    width: 106%;
    margin-top: -69px;
    margin-left: -7px;
    margin-bottom: -12px;
    height: 650px;
  }

  /* element弹出框样式 */
  .attenddialog .el-dialog {
    border-radius: 10px;
    /* min-height: 430px; */
    padding: 0;
    width: 1344px !important;
  }

  .attenddialog .el-dialog__body {
    padding: 0;
  }

  .attenddialog .demo-ruleForm {
    font-size: 19px;
    color: #000;
    min-height: 280px;
  }

  .attenddialog .el-form-item {
    margin-bottom: 26px;
    padding-right: 1px;
  }

  .demo-ruleForm .el-form-item__label {
    font-size: 19px;
    color: #000;
    padding: 0;
  }

  .attenddialog .el-button+.attenddialog .el-button {
    margin-left: 0px;
  }

  .attenddialog .el-button.is-plain:focus,
  .attenddialog .el-button.is-plain:hover {
    background: #21bd99;
    color: #ffffff;
    border-color: #dcdfe6;
  }

  .attenddialog .dialog-footer .el-button {
    background: #21bd99;
    color: #ffffff;
    border-color: #dcdfe6;
    width: 187px;
    height: 52px;
  }
</style>